import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles({});

export default function PostcodeDialog(props) {
  const classes = useStyles({
    root: {},
  });
  const { onClose, selectedValue, open, deliveryPoints } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  const toTitleCase = str =>
    str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <DialogTitle id="simple-dialog-title">Select your address</DialogTitle>
      <List>
        {deliveryPoints.map((deliveryPoint, key) => {
          const lines = [
            toTitleCase(deliveryPoint.organisation_name),
            toTitleCase(deliveryPoint.department_name),
            toTitleCase(deliveryPoint.line_1),
            toTitleCase(deliveryPoint.line_2),
          ];
          const filteredLines = lines.filter(el => {
            return el !== '';
          });
          const primary = filteredLines.join(', ');
          return (
            <ListItem
              button
              onClick={() => handleListItemClick(key)}
              key={deliveryPoint.udprn}
            >
              <ListItemText primary={primary} />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
}
