import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormControl';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withFormik } from 'formik';
import useCustomSnackbar from './useCustomSnackbar';
import RadioPopover from './RadioPopover';
import PostcodeDialog from './PostcodeDialog';

const useStyles = makeStyles(theme => ({
  success: {
    borderRadius: '10px',
    backgroundColor: '#66c1e7',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      padding: theme.spacing(0),
    },
  },
  step1: {
    borderRadius: '10px',
    backgroundColor: '#66c1e7',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      padding: theme.spacing(0),
    },
  },
  step2: {
    borderRadius: '10px',
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    backgroundColor: '#42b2e0',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      padding: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
  },
  step3: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  specialOffer: {
    backgroundColor: '#fcc37a',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  specialOfferText: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  link: {
    color: '#ffffff',
  },
  h2: {
    fontFamily: 'Roboto Slab, serif',
    fontWeight: 700,
    fontSize: '30px',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginBottom: theme.spacing(1),
    },
  },
  h3: {
    fontFamily: 'Roboto Slab, serif',
    fontWeight: 700,
    fontSize: '25px',
    textAlign: 'center',
    color: '#42b2e0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      marginBottom: theme.spacing(1),
    },
  },
  h4: {
    fontFamily: 'Roboto Slab, serif',
    fontWeight: 700,
    fontSize: '23px',
    textAlign: 'center',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '17px',
      marginBottom: theme.spacing(1),
    },
  },
  body: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  findAddress: {
    marginTop: '30px',
    textAlign: 'center',
  },
  packages: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  keyword: {
    textAlign: 'center',
    height: '94px',
  },
  agreeCheckbox: {
    maxWidth: '774px',
  },
  agreeHelperText: {
    textTransform: 'uppercase',
    fontSize: '18px',
  },
}));

const MyForm = props => {
  const {
    ReactGA,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    setFieldValue,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [deliveryPoints, setDeliveryPoints] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(0);

  const snackbar = useCustomSnackbar();
  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length) {
      ReactGA.event({
        category: 'Register',
        action: 'Failed validation',
      });
      snackbar.showError(
        <>
          Please correct the following errors:
          <ul>
            {Object.keys(errors).map(function(error) {
              return <li key={error}>{errors[error]}</li>;
            })}
          </ul>
        </>,
      );
    }
  }, [isSubmitting, errors, ReactGA, snackbar]);

  useEffect(() => {
    if (status && status.error_text) {
      ReactGA.event({
        category: 'Register',
        action: 'Submission Error',
      });
      snackbar.showError(
        <>
          Please correct the following errors:
          <ul>
            <li>{status.error_text}</li>
          </ul>
        </>,
      );
    } else if (status && status.data && status.data.message) {
      ReactGA.event({
        category: 'Register',
        action: 'User registered',
      });
      snackbar.showSuccess(status.data.message);
    }
  }, [status, ReactGA, snackbar]);

  useEffect(() => {
    if (status && status.error_text) {
      snackbar.showError(
        <>
          Please correct the following errors:
          <ul>
            <li>{status.error_text}</li>
          </ul>
        </>,
      );
    } else if (status && status.data && status.data.message) {
      snackbar.showSuccess(status.data.message);
    }
  }, [status, snackbar]);

  useEffect(() => {
    setDeliveryPoints([]);
  }, [values.postcode]);

  const toTitleCase = str =>
    str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

  useEffect(() => {
    if (deliveryPoints && selectedAddress in deliveryPoints) {
      const deliveryPoint = deliveryPoints[selectedAddress];
      if (deliveryPoint.organisation_name) {
        setFieldValue(
          'charityName',
          toTitleCase(deliveryPoint.organisation_name),
        );
      }
      const lines = [
        toTitleCase(deliveryPoint.department_name),
        toTitleCase(deliveryPoint.line_1),
        toTitleCase(deliveryPoint.line_2),
      ];
      const filteredLines = lines.filter(el => {
        return el !== '';
      });
      setFieldValue('address', filteredLines.join(', '));
    }
  }, [deliveryPoints, selectedAddress, setFieldValue]);

  const handleFindAddress = () => {
    if (!deliveryPoints.length) {
      ReactGA.event({
        category: 'Register',
        action: 'Postcode lookup',
      });
      fetch('https://pcls1.craftyclicks.co.uk/json/rapidaddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: '58f0c-86b7a-c8898-05b77',
          postcode: values.postcode,
          response: 'data_formatted',
        }),
      })
        .then(response => response.json())
        .then(data => {
          setOpen(true);
          setDeliveryPoints(data.delivery_points);
          setFieldValue('city', toTitleCase(data.town));
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      setOpen(true);
    }
  };

  const handlePostcodeDialogClose = value => {
    setSelectedAddress(value);
    setOpen(false);
  };

  if (status && status.data && status.data.message) {
    return (
      <Grid container className={classes.success} spacing={1}>
        <Grid item xs={12}>
          <Typography component="h2" className={classes.h2}>
            Your registration was successful.
          </Typography>
          <Typography component="h4" className={classes.h4}>
            Please check your emails for further details.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <PostcodeDialog
        onClose={handlePostcodeDialogClose}
        selectedValue={selectedAddress}
        open={open}
        deliveryPoints={deliveryPoints}
      />
      <Grid container className={classes.step1} spacing={1}>
        <Grid item xs={12}>
          <Typography component="h2" className={classes.h2}>
            Step 1
          </Typography>
          <Typography className={classes.body}>
            instaGiv only work with Registered UK Charities and you must be
            registered with the regulator Phone-paid Services Authority. Please
            ensure you have your HMRC charity number and Phone-paid Services
            Authority registration number. You can register here for free:{' '}
            <Link
              className={classes.link}
              href="https://psauthority.org.uk/registration/register"
              target="_blank"
            >
              https://psauthority.org.uk/registration/register
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={6} className={classes.paper}>
            <Typography component="h3" className={classes.h3}>
              Charity Details
            </Typography>
            <TextField
              fullWidth
              error={errors.charityName && touched.charityName}
              label="Charity Name*"
              name="charityName"
              className={classes.textField}
              value={values.charityName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.charityName &&
                  touched.charityName &&
                  errors.charityName) ||
                ' '
              }
              margin="normal"
            />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={8}>
                <TextField
                  fullWidth
                  error={errors.postcode && touched.postcode}
                  label="Postcode*"
                  name="postcode"
                  className={classes.textField}
                  value={values.postcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (errors.postcode && touched.postcode && errors.postcode) ||
                    ' '
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} className={classes.findAddress}>
                <Button
                  disabled={
                    values.postcode === '' || errors.postcode !== undefined
                  }
                  variant="contained"
                  color="primary"
                  onClick={handleFindAddress}
                >
                  Find Address
                </Button>
              </Grid>
            </Grid>
            <TextField
              fullWidth
              error={errors.address && touched.address}
              label="Address*"
              name="address"
              className={classes.textField}
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.address && touched.address && errors.address) || ' '
              }
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.city && touched.city}
              label="City*"
              name="city"
              className={classes.textField}
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={(errors.city && touched.city && errors.city) || ' '}
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.website && touched.website}
              label="Website Address*"
              name="website"
              className={classes.textField}
              value={values.website}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.website && touched.website && errors.website) || ' '
              }
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.phone && touched.phone}
              label="Customer Service Landline Number*"
              name="phone"
              className={classes.textField}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.phone && touched.phone && errors.phone) || ' '
              }
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.commissionNum && touched.commissionNum}
              label="Charity Commission Number*"
              name="commissionNum"
              className={classes.textField}
              value={values.commissionNum}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.commissionNum &&
                  touched.commissionNum &&
                  errors.commissionNum) ||
                ' '
              }
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.pppNumber && touched.pppNumber}
              label="Phone-paid Services Authority Org Number*"
              name="pppNumber"
              className={classes.textField}
              value={values.pppNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.pppNumber && touched.pppNumber && errors.pppNumber) ||
                ' '
              }
              margin="normal"
              placeholder="ORG###-#####-#####"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={6} className={classes.paper}>
            <Typography component="h3" className={classes.h3}>
              Your Details
            </Typography>
            <TextField
              fullWidth
              error={errors.firstName && touched.firstName}
              label="First Name*"
              name="firstName"
              className={classes.textField}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.firstName && touched.firstName && errors.firstName) ||
                ' '
              }
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.surname && touched.surname}
              label="Last Name*"
              name="surname"
              className={classes.textField}
              value={values.surname}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.surname && touched.surname && errors.surname) || ' '
              }
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.position && touched.position}
              label="Job Title*"
              name="position"
              className={classes.textField}
              value={values.position}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.position && touched.position && errors.position) || ' '
              }
              margin="normal"
            />
            <TextField
              fullWidth
              error={errors.email && touched.email}
              label="Email*"
              name="email"
              className={classes.textField}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.email && touched.email && errors.email) || ' '
              }
              margin="normal"
            />
          </Paper>
          <Paper elevation={6} className={classes.paper}>
            <FormControl
              fullWidth
              error={errors.source && touched.source}
              className={classes.textField}
            >
              <InputLabel shrink id="source">
                How did you hear about us?
              </InputLabel>
              <Select
                labelId="source"
                id="source"
                name="source"
                value={values.source}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="Word of mouth">Word of mouth</MenuItem>
                <MenuItem value="Referral">Referral</MenuItem>
                <MenuItem value="Search engine">Search engine</MenuItem>
                <MenuItem value="Social media">Social media</MenuItem>
                <MenuItem value="Industry network">Industry network</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      <Grid container className={classes.step2} spacing={1}>
        <Grid item xs={12}>
          <Typography component="h2" className={classes.h2}>
            Step 2
          </Typography>
          <Typography className={classes.body}>
            Select a package type:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={6} className={classes.paper}>
            <Typography component="h3" className={classes.h3}>
              Package Details
            </Typography>
            <RadioGroup
              error={errors.keywordType && touched.keywordType}
              className={classes.packages}
              name="keywordType"
              value={values.keywordType}
              onChange={handleChange}
            >
              <RadioPopover
                value="1"
                label="Single Giving"
                tooltip="Receive one off donations from supporters."
              />
              <RadioPopover
                value="2"
                label="Regular Giving"
                tooltip="Receive monthly donations from supporters."
              />
              <RadioPopover
                value="6"
                label="Response SMS"
                tooltip="Allow supporters to respond to your outbound SMS; collect registrations, event sign up, support for petitions and much more."
              />
              <RadioPopover
                value="99"
                label="Outbound SMS"
                tooltip="Send text messages to thousands of supporters."
              />
              <RadioPopover
                value="13"
                label="Address Collection"
                tooltip="Capture verified addresses from your supporters."
              />
              <RadioPopover
                value="7"
                label="Competition"
                tooltip="Enter a competition via SMS and allow your supporters to also give a voluntary donation."
              />
            </RadioGroup>
            <div className={classes.keyword}>
              {values.keywordType !== '99' && (
                <TextField
                  error={errors.keyword && touched.keyword}
                  label="Choose Keyword*"
                  name="keyword"
                  className={classes.textField}
                  value={values.keyword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (errors.keyword && touched.keyword && errors.keyword) || ' '
                  }
                  margin="normal"
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container className={classes.step3} spacing={1}>
        <Grid item xs={12} lg={9}>
          <FormControl
            error={errors.agree && touched.agree}
            className={classes.agreeCheckbox}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={1}
                    checked={values.agree}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="agree"
                  />
                }
                label={
                  <>
                    {'I have read and agree to the '}
                    <Link
                      href="https://www.instagiv.com/TermsAndConditions.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </Link>
                    {
                      ' and I am authorised to enter into this agreement. I give permission for instaGiv to process text donations on the behalf of the charity being registered.'
                    }
                  </>
                }
              />
            </FormGroup>
            <FormHelperText className={classes.agreeHelperText}>
              {errors.agree && touched.agree && errors.agree}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Button
            type="submit"
            disabled={isSubmitting}
            variant="contained"
            size="large"
            color="secondary"
            className={classes.margin}
          >
            Register Now
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const SignUpForm = withFormik({
  mapPropsToValues: () => ({
    charityName: '',
    postcode: '',
    address: '',
    city: '',
    website: '',
    phone: '',
    commissionNum: '',
    pppNumber: '',
    firstName: '',
    surname: '',
    position: '',
    email: '',
    source: '',
    keywordType: '1',
    keyword: '',
    agree: false,
  }),

  validate: (values, props) => {
    const errors = {};

    if (!values.charityName) {
      errors.charityName = 'Charity name is a required field';
    }

    if (!values.postcode) {
      errors.postcode = 'Postcode is a required field';
    } else if (
      !/^(GIR 0AA)|((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY])))) ?[0-9][ABDEFGHJLNPQRSTUWXYZ]{2})$/i.test(
        values.postcode,
      )
    ) {
      errors.postcode = 'Postcode is not valid';
    }

    if (!values.address) {
      errors.address = 'Address is a required field';
    }

    if (!values.city) {
      errors.city = 'City is a required field';
    }

    if (!values.website) {
      errors.website = 'Website address is a required field';
    }

    if (!values.phone) {
      errors.phone = 'Customer service contact number is a required field';
    }

    if (!values.commissionNum) {
      errors.commissionNum = 'Charity commission number is a required field';
    }

    if (!values.pppNumber) {
      errors.pppNumber = 'PSA number is required';
    } else if (!/^ORG[0-9]{3}-[0-9]{5}-[0-9]{5}$/i.test(values.pppNumber)) {
      errors.pppNumber = 'PSA number must match the format ORGXXX-XXXXX-XXXXX';
    }

    if (!values.firstName) {
      errors.firstName = 'First name is a required field';
    }

    if (!values.surname) {
      errors.surname = 'Last name is a required field';
    }

    if (!values.position) {
      errors.position = 'Job title is a required field';
    }

    if (!values.email) {
      errors.email = 'Email is a required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Email address is not valid';
    }

    if (!values.keyword && values.keywordType !== '7') {
      errors.keyword = 'Keyword is a required field';
    }

    if (!values.agree) {
      errors.agree =
        'You must accept the Terms and Conditions before proceeding';
    }

    return errors;
  },

  handleSubmit: (values, props) => {
    const { setStatus, setSubmitting } = props;
    const body = JSON.stringify({
      ...values,
      keywordType: parseInt(values.keywordType),
    });
    fetch(process.env.REACT_APP_IG2V2_URL + '/register/charity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(response => response.json())
      .then(status => {
        setStatus(status);
        if (status.error_text) {
          setSubmitting(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setSubmitting(false);
      });
  },
})(MyForm);

export default SignUpForm;
