import React from 'react';
import { useSnackbar } from 'material-ui-snackbar-provider';

export default function useCustomSnackbar() {
  const snackbar = useSnackbar();
  return React.useMemo(() => {
    const showMessage = (type, title) => (
      message,
      action,
      handleAction,
      customParameters,
    ) =>
      snackbar.showMessage(message, action, handleAction, {
        ...customParameters,
        type,
        title,
      });
    return {
      ...snackbar,
      showMessage: showMessage('info', 'Info'),
      showInfo: showMessage('info', 'Info'),
      showWarning: showMessage('warning', 'Warning'),
      showError: showMessage('error', 'Error'),
      showSuccess: showMessage('success', 'Success'),
    };
  }, [snackbar]);
}
